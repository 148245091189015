<template>
  <div class="app app-error align-content-stretch d-flex flex-wrap">
    <div class="app-error-info">
      <h5>
        {{
          $t(
            "Components.404.Oops",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h5>
      <span>{{
        $t(
          "Components.404.Description",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</span>
      <router-link :to="{ path: '/' }" class="btn btn-primary">{{
        $t(
          "Components.404.GotoDashboard",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link>
    </div>
    <div class="app-error-background"></div>
  </div>
</template>
<script>
export default {
  name: "404",
};
</script>
